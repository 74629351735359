<template>
<div>
<vs-row>
        <vs-button
          class="ml-auto"
          align="right"
          @click="createRoleHandler"
        >Create New Role
        </vs-button>
        </vs-row>

    <vs-popup class="holamundo"  title="Create New Role" :active.sync="popupActivo">
        <vs-row>
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">
             <div class="w-full ml-5 mr-5 mt-1">
            <vs-input
              :danger="errors.first('role') ? true : false"
              :success="
                !errors.first('role') &&
                  role != ''
              "
              val-icon-success="done"
              val-icon-danger="error"
              v-validate="'required'"
              data-vv-validate-on="blur"
              data-vv-as="Role Name"
              label-placeholder="Role Name"
              name="role"
              placeholder="Role Name"
              class="w-full mt-2"
              v-model="role"
            />
            <span class="text-danger text-sm">{{ errors.first("role") }}</span>
          </div>
          </vs-col>

          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">
               <div class="w-full ml-5 mr-5 mt-2">
              <vs-button
                align="right"
                @click="submitRoleHandler"
                >Submit
                </vs-button>
               </div>
          </vs-col>
        </vs-row>
      </vs-popup>
      </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
    name: "CreateRole",
    data(){
        return{
            role: "",
            popupActivo: false
        }
    },

    methods: {
      ...mapActions("rolesAndPermissions", ["createRole"]),
        createRoleHandler(){
          this.popupActivo = true;
        },
        submitRoleHandler(){
            this.$vs.loading();
            this.createRole({role: this.role}).then(result => {
              this.$vs.notify({
                title: "Success",
                text: "Role successfully added",
                color: "success",
              });
              this.popupActivo = false;
              this.$vs.loading.close();
              return result;
            }).then(result => {
              this.$emit("forceRerender", result);
            }).catch(err => {
              this.$vs.notify({
                title: "Failed",
                text: err.data.message,
                color: "danger",
              });
              this.$vs.loading.close();
            });
        }
    }
}
</script>
