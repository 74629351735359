<template>
    <vx-card style="padding-left: 10px" :key="rerenderKey">

      <CreateRole @forceRerender="forceRerender" />

    <div id="data-list-list-view" class="data-list-container">
      <vs-table
        ref="table"
        :sst="true"
        :total="totalDocs"
        :data="roles"
        @search="handleSearch"
        @change-page="handleChangePage"
        @sort="handleSort"
        :max-items="dataTableParams.limit"
        search
        :noDataText="noDataText"
      >

      <div slot="header" class="flex flex-wrap-reverse flex-grow justify-between">
          <vs-select placeholder="10" autocomplete v-model="dataTableParams.limit">
            <vs-select-item
              :key="index"
              :value="item"
              :text="item"
              v-for="(item,index) in limitOptions"
            />
          </vs-select>
        </div>

         <template slot="thead">
          <vs-th sort-key="role">Role</vs-th>
          <vs-th>Permissions</vs-th>
        </template>

          <template slot-scope="{data}">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="data[indextr].role">{{ data[indextr].role | capitalize}}</vs-td>
            <vs-td :data="data[indextr]._id">
              <vx-tooltip text="View/Edit Permissions">
                <vs-button
                  type="border"
                  size="small"
                  icon-pack="feather"
                  icon="icon-edit"
                  @click="viewHandler(data[indextr]._id)"
                  class="mr-2"
                ></vs-button>
              </vx-tooltip>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>

       <div class="m-2">
        <span class="mr-2">
          {{
          dataTableParams.page * dataTableParams.limit -
          (dataTableParams.limit - 1)
          }}
          -
          {{
          totalDocs - dataTableParams.page * dataTableParams.limit > 0
          ? dataTableParams.page * dataTableParams.limit
          : totalDocs
          }}
          of {{ totalDocs }}
        </span>
        <div class="pagination-div" v-if="serverResponded">
          <paginate
            :page-count="totalPage"
            :click-handler="handleChangePage"
            class="pagination"
            :page-range="9"
            :prevText="'<'"
            :nextText="'>'"
          ></paginate>
        </div>
      </div>
    </div>
    </vx-card>
</template>

<script>
import { mapActions } from "vuex";
import CreateRole from "./CreateRole";
export default {
    components: {
      CreateRole
    },
    data(){
      return{
        noDataText: "Loading...",
        isMounted: false,
        totalDocs: 0,
        currentPage: 1,
        rerenderKey: 0,
        dataTableParams: {
          search: "",
          sort: "role",
          dir: "asc",
          page: 1,
          limit: 25
        },
        serverResponded: false,
        awaitingSearch: null,
        roles: [],
        limitOptions: [5, 10, 25, 50, 100],
      }
    },
    methods: {
        ...mapActions("rolesAndPermissions", ["fetchRoles"]),
        getAvailableRoles(){
          let self  = this;
          this.fetchRoles(self.dataTableParams).then(result => {
            self.serverResponded = true;
            self.roles = result.data.data.docs;
            self.totalDocs = result.data.data.pagination.total;
            self.page = result.data.data.pagination.page;
            self.currentPage = result.data.data.pagination.page;
            if(result.data.data.docs.length === 0)
              this.noDataText = "No roles found";
          }).catch(err => console.log(err));
        },
        handleSort(key, active) {
          this.serverResponded = false;
          this.dataTableParams.sort = key;
          this.dataTableParams.dir = active;
          this.dataTableParams.page = 1;
          this.$refs.table.currentx = 1;
          this.getAvailableRoles();
        },
        handleSearch(searching) {
          if (this.awaitingSearch) {
            clearTimeout(this.awaitingSearch);
            this.awaitingSearch = null;
          }
          this.awaitingSearch = setTimeout(() => {
            this.serverResponded = false;
            this.dataTableParams.search = searching;
            this.dataTableParams.page = 1;
            this.$refs.table.currentx = 1;
            this.getAvailableRoles();
          }, 500);
        },
        handleChangePage(page) {
          this.dataTableParams.page = page;
          this.getAvailableRoles();
        },
        forceRerender(createdRole) {
          this.$router.push(`/super-admin/roles/${createdRole.data.data._id}/assign-permission`)
          this.rerenderKey += 1;
        },
        viewHandler(roleId){
          this.$router.push(`/super-admin/roles/${roleId}/assign-permission`)
        }
    },
    computed: {
      totalPage: function () {
        return this.totalDocs / this.dataTableParams.limit >
          parseInt(this.totalDocs / this.dataTableParams.limit)
          ? parseInt(this.totalDocs / this.dataTableParams.limit) + 1
          : parseInt(this.totalDocs / this.dataTableParams.limit);
      },
    },
    watch: {
      "dataTableParams.page": function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.dataTableParams.page = newVal;
          this.getAvailableRoles();
        }
      },
      "dataTableParams.limit": function (newlimit, oldLimit) {
        if (newlimit !== oldLimit) {
          this.serverResponded = false;
          this.dataTableParams.page = 1;
          this.dataTableParams.limit = newlimit;
          this.getAvailableRoles();
        }
      },
      rerenderKey: function(newVal, oldVal) {
        if (oldVal !== newVal) {
          this.getAvailableRoles();
        }
      }
    },
    created(){
      this.getAvailableRoles();
    }
}
</script>